@font-face {
  font-family: 'Montserrat', 'sans-serif';
  src: url('../public/fonts/mons-play/Montserrat/static/Montserrat-Bold.ttf');
  src: url('../public/fonts/mons-play/Montserrat/static/Montserrat-SemiBold.ttf');
  src: url('../public/fonts/mons-play/Montserrat/static/Montserrat-Regular.ttf');
  src: url('../public/fonts/mons-play/Montserrat/static/Montserrat-Thin.ttf');
  src: url('../public/fonts/mons-play/Montserrat/static/Montserrat-Medium.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Playfair Display', 'serif';
  src: url('../public/fonts/mons-play/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf');
  src: url('../public/fonts/mons-play/Playfair_Display/static/PlayfairDisplay-Bold.ttf');
  src: url('../public/fonts/mons-play/Playfair_Display/static/PlayfairDisplay-Medium.ttf');
  src: url('../public/fonts/mons-play/Playfair_Display/static/PlayfairDisplay-Regular.ttf');
  font-weight: bold;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* custom css */

.animated-bg {
  visibility: visible;
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
